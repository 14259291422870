export const saveCateogry = category => {
	localStorage.setItem( 'category', JSON.stringify( category ) );
};

export const getCategory = () => {

	if ( typeof window === 'undefined' ) {
		return [];
	}

	const category = JSON.parse( localStorage.getItem( 'category' ) ) ? JSON.parse( localStorage.getItem( 'category' ) ) : [];

	return category;
};