const AuthReducer = (state, action) => {

	const initialState = {
		user: {},
		loggedIn: false
	}

	switch ( action.type ) {
		case 'LOGIN':
		case 'REGISTER':
		case 'UPDATE_PROFILE':
		case 'CHANGE_PASSWORD':
			const { user = {} } = action.payload
			localStorage.setItem( 'user', JSON.stringify( {...state, user, loggedIn: true} ) );

			return {
				...state,
				user,
				loggedIn: true
			}
		case 'LOGOUT':

			localStorage.removeItem( 'user' );
			return {
				...state,
				user: {},
				loggedIn: false
			}
		default:
			return initialState
	}
}

export default AuthReducer;