import React, { useState, useEffect } from "react";
import { Link } from "gatsby"

const CookiePolicy = () => {

	const [ cookiePolicyChosen, setCookiePolicyChosen ] = useState( true )
	const [ displayCookieNotice, setDisplayCookieNotice ] = useState( false )

	const handleSubmit = () => {
		localStorage.setItem( 'cookie-policy-chosen', true );
		setCookiePolicyChosen( true )
	}

	const checkCookieOnLoad = () => {
		const cookiePolicyChosen  = localStorage.getItem( 'cookie-policy-chosen' )

		if ( !cookiePolicyChosen || cookiePolicyChosen !== 'true' ) {
			setDisplayCookieNotice( true );
		}
	};

	useEffect(() => {
		setCookiePolicyChosen( localStorage.getItem( 'cookie-policy-chosen') )
		checkCookieOnLoad()
	}, [] )

	return(
		<>
			{ displayCookieNotice &&
				<section className={`cookie-notice ${ cookiePolicyChosen ? 'is-hidden' : '' }`}>
					<div className="wrapper">
						<div className="cookie-notice__body">
							<div className="cookie-notice__text">
								<h4 className="cookie-notice__title">Cookies on Hoarders</h4>
								<p className="cookie-notice__message">We use cookies to give you the best possible online experience. If you continue, we’ll assume you are happy for your web browser to receive all cookies from our website. See our <Link to="/cookie-policy">cookie policy</Link> for more information on cookies and how to manage them.</p>
							</div>
							<button className="cookie-notice__button button button--no-margin"
								value={ true }
								onClick={ () => handleSubmit() }>
								Accept
							</button>
						</div>
					</div>
				</section>
			}
		</>
		)
}

export default CookiePolicy;