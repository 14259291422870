export const saveCart = ( cart ) => {
	localStorage.setItem( 'cart', JSON.stringify( cart ) );
};

export const getCart = () => {

	if ( typeof window === 'undefined' ) {
		return [];
	}

	const cart = JSON.parse( localStorage.getItem( 'cart' ) ) ? JSON.parse( localStorage.getItem( 'cart' ) ) : [];

	return cart;
};

export const cartSubTotal = ( cart ) => {
	// Sum up all the individual product costs
	const subTotal = cart.reduce( ( counter, product ) => { 
		return counter + product.price * product.quantity;
	}, 0 );

	return Math.round( subTotal * 100 ) / 100;
}

export const weightTotal = ( cart ) => {
	// Sum up all the individual product costs
	const subTotal = cart.reduce( ( counter, product ) => { 
		return counter + product.weightInKg * product.quantity;
	}, 0 );

	return subTotal;
}

export const cartTotal = ( cart, postage ) => {
	if ( cart.length === 0 ) {
		return 0;
	}

	const subTotal = cartSubTotal( cart );
	const shipping = postage.price;
	const total = subTotal + shipping;

	return Math.round( total * 100 ) / 100;
}
