export const GTAddToCart = ( product, quantity ) => {

	if ( window !== undefined && window.gtag !== undefined ) {

		window.gtag( 'event', 'add_to_cart', {
			items: [
				{
					item_id: product.id,
					item_name: product.name,
					item_brand: product.manufacturer?.name,
					item_category: product.categories?.[ 0 ]?.name,
					price: product.price,
					quantity: quantity,
					currency: 'GBP'
				}
			]
		} )
	}
}

export const GTRemoveFromCart = product => {

	if ( window !== undefined && window.gtag !== undefined ) {

		window.gtag( 'event', 'remove_from_cart', {
			items: [
				{
					item_id: product.id,
					item_name: product.name,
					item_brand: product.manufacturer?.name,
					item_category: product.categories?.[ 0 ]?.name,
					price: product.price,
					quantity: product.quantity,
					currency: 'GBP'
				}
			]
		} )
	}
}

export const GTViewItem = product => {

	if ( window !== undefined && window.gtag !== undefined ) {

		window.gtag( 'event', 'view_item', {
			items: [
				{
					item_id: product.id,
					item_name: product.name,
					item_brand: product.manufacturer?.name,
					item_category: product.categories?.[ 0 ]?.name,
					price: product.price
				}
			]
		} )
	}
}

export const GTViewCart = cart => {

	if ( window !== undefined && window.gtag !== undefined ) {

		let items = []

		cart.forEach( product => {
			const productData = {
				item_id: product.id,
				item_name: product.name,
				item_brand: product.manufacturer?.name,
				item_category: product.categories?.[ 0 ]?.name,
				price: product.price,
				quantity: product.quantity
			}

			items.push( productData )
		} )

		window.gtag( 'event', 'view_cart', {
			items
		} )
	}
}

export const GTPurchase = ( cart, gtData ) => {

	if ( window !== undefined && window.gtag !== undefined ) {

		let items = []

		cart.forEach( product => {
			const productData = {
				item_id: product.id,
				item_name: product.name,
				item_brand: product.manufacturer?.name,
				item_category: product.categories?.[ 0 ]?.name,
				price: product.price,
				quantity: product.quantity
			}

			items.push( productData )
		} )

		window.gtag( 'event', 'purchase', {
			currency: 'GBP',
			transaction_id: gtData.transactionId,
			value: gtData.value,
			shipping: gtData.postagePrice,
			items
		} )
	}
}