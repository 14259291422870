import React, { useState } from 'react'
import { Link } from 'gatsby'

import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri'

export default function NavItemLevelThree ( props ) {

	const { category, toggleMenu } = props
	const [ active, setActive ] = useState( false )

	const toggleSubNav = () => {
		setActive( !active )
	}

	return (
		<li className="navigation__dropdown-option">
			<div className={`navigation__dropdown navigation__dropdown--has-more ${active ? 'is-open': ''}`}>
				<div className="navigation__link-container">
					<Link
						to={ category.navigationSlug }
						className="navigation__link"
						activeClassName="active"
						onClick={() => toggleMenu( false )}>
						{ category.name }
					</Link>
					<RiArrowDownSLine className={`navigation__dropdown-icon u-show-tablet ${active ? 'is-open': ''}`} onClick={ () => toggleSubNav(  ) } />
					<RiArrowRightSLine className={`navigation__dropdown-icon u-hide-tablet ${active ? 'is-open': ''}`} onClick={ () => toggleSubNav(  ) } />
				</div>
				<ul className={`navigation__dropdown-options navigation__dropdown-options--in-dropdown ${active ? 'is-open': ''}`}>
					{ category.subCategories.map( subCategory => (
						<li key={ subCategory.id } className="navigation__dropdown-option">
							<Link
								to={ subCategory.navigationSlug }
								className="navigation__link navigation__link--in-dropdown"
								activeClassName="active"
								onClick={() => toggleMenu( false )}>
								{ subCategory.name }
							</Link>
						</li>
					) ) }
				</ul>
			</div>
		</li>
	)
}