import React from 'react';
import { Link } from "gatsby"

export default function Footer() {

	return (
		<footer className="footer">
			<div className="footer__wrapper wrapper">
				<div className="footer__copyright">
					© {new Date().getFullYear()}, Hoarders, All Rights Reserverd
				</div>
				<div className="footer__links-container">
					<ul className="footer__links">
						<li className="footer__links-item">
							<Link to="/about-us" className="footer__link">About Us</Link>
						</li>
						<li className="footer__links-item">
							<Link to="/contact-us" className="footer__link">Contact Us</Link>
						</li>
						<li className="footer__links-item">
							<Link to="/shipping" className="footer__link">Shipping</Link>
						</li>
						<li className="footer__links-item">
							<Link to="/terms-and-conditions" className="footer__link">Ts&Cs</Link>
						</li>
						<li className="footer__links-item">
							<Link to="/privacy-policy" className="footer__link">Privacy Policy</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	)
}
