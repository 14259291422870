import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import { CartContext } from '../context/CartContext';

import SiteLogo from '../images/svgs/hoarders-logo.svg'

import {
	RiUserLine,
	RiShoppingBagLine,
	RiSearchLine } from 'react-icons/ri'

import Navigation from './Navigation/Navigation'
import CartHeader from './Navigation/Cart'
import AccountHeader from './Navigation/Account'
import Search from './Search/Search'

import { MEASUREMENTS } from '../helpers/constants'

export default function Header ( props ) {

	const { cart } = useContext( CartContext );

	const [ menuToggled, toggleMenu ] = useState(false);
	const [ cartVisible, showCart ] = useState(false);
	const [ accountVisible, showAccount ] = useState(false);
	const [ searchVisible, showSearch ] = useState(false);

	const onHover = ( trigger ) => {
		if ( window.innerWidth > MEASUREMENTS.TABLET ) {
			trigger( true )
		}
	}

	return (
		<header className={`navigation ${ menuToggled ? 'is-open' : '' }`}>

			<div className="navigation__container">

				<button
					className="navigation__mobile-menu-toggle u-show-tablet"
					onClick={ () =>toggleMenu( !menuToggled ) }
					aria-label="Toggle main menu"
				>
					<div className={`hamburger ${ menuToggled ? 'active' : '' }`}>
						<span className="hamburger__box">
							<span className="hamburger__inner"></span>
						</span>
					</div>
				</button>

				<Link to="/" className="navigation__title-link">
					<SiteLogo className="navigation__logo" alt="" />
				</Link>

				<Navigation toggleMenu={ toggleMenu } />

				<div className={`navigation__search-container ${ searchVisible ? 'active' : '' }`}>
					{ searchVisible &&
						<Search toggleSearch={ showSearch } />
					}
				</div>

				<div className={`navigation__search-background ${ searchVisible ? 'active' : '' }`}></div>

				<div className="navigation__meta">
					<button
						onClick={() => showSearch( !searchVisible )}
						tabIndex={0}
						aria-label="Toggle search bar"
					>
						<RiSearchLine className='navigation__icon' />
					</button>

					<button
						className="navigation__account-container"
						onClick={() => showAccount( !accountVisible )}
						onMouseEnter={ () => onHover( showAccount ) }
						onMouseLeave={() => showAccount( false )}
						tabIndex={0}
						aria-label="Show account options"
					>
						<RiUserLine className='navigation__icon' />
					</button>

					<button
						className="navigation__cart-container"
						onClick={() => showCart( !cartVisible )}
						onMouseEnter={ () => onHover( showCart ) }
						onMouseLeave={() => showCart( false )}
						tabIndex={0}
					>
						<RiShoppingBagLine className='navigation__icon' />
						{ cart && cart.length > 0 &&
							<span className="navigation__cart-quantity">
								{ cart.reduce( ( counter, product ) => {
									return counter + product.quantity;
								}, 0 ) }
							</span>
						}
					</button>

					<AccountHeader isOpen={ accountVisible } />
					<CartHeader isOpen={ cartVisible } />
				</div>
			</div>
		</header>
	)
}
