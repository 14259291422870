import { useContext } from 'react';
import axios from 'axios'

import AuthContext from '../context/auth/AuthContext';

export default function useAuth() {
	const { state, dispatcher, loadingUser } = useContext( AuthContext )
	const isAuthenticated = loadingUser ? false : state.loggedIn

	const login = async ( credentials ) => new Promise( async( resolve, reject ) => {
		try {
			const { data: payload } = await axios.post('/api/auth/local', credentials)
			dispatcher({ type: 'LOGIN', payload })
			resolve(payload)
		}
		catch( e ) {
			console.log(e)
			reject(e)
		}
	} )

	const logout = async () => {
		await axios.get('/api/user/logout')
		dispatcher({ type: 'LOGOUT' })
	}

	const register = async ( credentials ) => new Promise( async( resolve, reject ) => {
		try {
			const { data: payload } = await axios.post( '/api/auth/local/register/', credentials );
			dispatcher({ type: 'REGISTER', payload })
			resolve(payload)
		}
		catch( e ) {
			console.log(e)
			reject(e)
		}
	} )

	const updateProfile = async ( data, userId ) => new Promise( async( resolve, reject ) => {

		try {
			const userIdParam = state.user.id ? state.user.id : userId;

			const { data: payload } = await axios.put( `/api/users/${userIdParam}`, data );

			dispatcher({ type: 'UPDATE_PROFILE', payload })
			resolve( payload )
		}
		catch( e ) {
			console.log(e)
			reject(e)
		}
	} )

	const changePassword = async ( data ) => new Promise( async( resolve, reject ) => {

		try {
			const { data: payload } = await axios.post( '/api/auth/reset-password', data );

			dispatcher({ type: 'CHANGE_PASSWORD', payload })
			resolve( payload )
		}
		catch( e ) {
			console.log(e)
			reject(e)
		}
	} )

	return { state, isAuthenticated, login, logout, register, updateProfile, changePassword, loadingUser }
}