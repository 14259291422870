import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import NavItemLevelTwo from './NavItemLevelTwo'

export default function Navigation ( props ) {

	const { toggleMenu } = props

	let navigation = [];
	let data = useStaticQuery(graphql`
		query CategoryNavQuery {
			strapi {
				categories(sort: "level, name") {
					name
					slug
					id
					level
					categories {
						name
						id
						slug
					}
					category {
						id
					}
				}
			}
		}
	`)

	const addSubCategories = ( node, parentSlug ) => {
		node.categories.sort( ( a, b ) =>  a.name > b.name ? 1 : -1 )
		node.subCategories = [];
		
		node.categories.forEach( category => {
			const foundCategory = data.strapi.categories.find( object => category.id === object.id );

			node.subCategories.push( foundCategory );
			foundCategory.subCategories = [];
			foundCategory.navigationSlug = `/${parentSlug}/${foundCategory.slug}`;

			if ( foundCategory.categories.length ) {
				const slugWithoutSlash = `${parentSlug}/${foundCategory.slug}`;
				foundCategory.subCategories = addSubCategories( foundCategory, slugWithoutSlash );
			}
		} )

		return node.subCategories
	}

	const generateNavigationStructure = () => {

		data.strapi.categories.map( node => {

			if ( node.level === 1 ) {
				navigation.push( node );
				node.subCategories = [];
				node.navigationSlug = `/${node.slug}`;

				if ( node.categories.length ) {
					node.subCategories = addSubCategories( node, node.slug );
				}
			}

			return null;
		} );

		return navigation;
	}

	generateNavigationStructure();

	return (
		<nav className="navigation__menu">
			<ul className="navigation__list">
				{ navigation.map( category => (
					<React.Fragment key={ category.id }>
						{ category.subCategories.length ? (
							<NavItemLevelTwo category={ category } toggleMenu={ toggleMenu } />
						) : (
							<li className="navigation__item">
								<Link
									to={ category.navigationSlug }
									className="navigation__link"
									activeClassName="active"
									onClick={() => toggleMenu( false )}>
									{ category.name }
								</Link>
							</li>
						) }
					</React.Fragment>
				) ) }
			</ul>
		</nav>
	)
}
