import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types"
import { Link } from "gatsby"

import useAuth from '../../hooks/useAuth'

import { RiUserSettingsLine, RiWalletLine, RiLogoutBoxRLine } from 'react-icons/ri'

export default function AccountHeader( props ) {

	const [ accountVisible, showAccount ] = useState(false);
	const [ authenticatedClass, setAuthenticatedClass ] = useState( false );

	const { state ,isAuthenticated, logout } = useAuth()

	const handleLogout = ( event ) => {
		event.preventDefault()
		logout()
		showAccount( false )
	};

	useEffect(() => {
		setAuthenticatedClass( isAuthenticated );
	}, [ isAuthenticated ])

	return (
		<div
			className={ `account-header ${ props.isOpen || accountVisible ? 'open' : '' }` }
			onMouseOver={ () => showAccount( true ) }
			onFocus={() => showAccount( true )}
			onMouseLeave={() => showAccount( false )}
			role="menuitem"
			tabIndex={0}>

			<div className={`account-header__header ${ !authenticatedClass ? 'account-header__header--logged-out' : '' }`}>
				{ isAuthenticated ? (
					<>
						<div className="account-header__greeting">Hi { state.user.firstName ? state.user.firstName : '' }!</div>
						<a href="#logout" className="account-header__header-link" onClick={ e => handleLogout(e) }>
							Logout
							<RiLogoutBoxRLine className="account-header__logout-icon" />
						</a>
					</>
				) : (
					<>
						<Link to="/login" className="account-header__header-link" onClick={() => showAccount( false )}>
							Login
						</Link>
						<Link to="/register" className="account-header__header-link" onClick={() => showAccount( false )}>
							Register Now
						</Link>
					</>
				) }
			</div>

			<div className="account-header__body">
				<Link to="/account" className="account-header__link" onClick={() => showAccount( false )}>
					<RiUserSettingsLine className="account-header__link-icon" />
					My Account
				</Link>
				<Link to="/account/orders" className="account-header__link" onClick={() => showAccount( false )}>
					<RiWalletLine className="account-header__link-icon" />
					My Orders
				</Link>
			</div>
		</div>
	)
}

AccountHeader.propTypes = {
	isOpen: PropTypes.bool,
}
