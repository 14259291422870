import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

import { CartContext } from '../../context/CartContext';

import { cartSubTotal } from '../../helpers/cart';
import { formatPrice } from '../../helpers/currency';

import { RiDeleteBinLine } from 'react-icons/ri'

export default function CartHeader( props ) {

	const isInitialMount = useRef( true )
	const { cart, removeFromCart, triggerCartEvent } = useContext( CartContext )
	const [ cartVisible, showCart ] = useState(false)
	const [ active, setActive ] = useState(false)

	useEffect(() => {
		if ( isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			if ( triggerCartEvent || active ) {
				showCart( true )
			} else {
				showCart( false )
			}
		}
	}, [ triggerCartEvent, active ])

	const handleShowCart = ( isCartVisible ) => {
		setActive( isCartVisible )
		showCart( isCartVisible )
	}

	return (
		<div
			className={ `cart-header ${ props.isOpen || cartVisible ? 'open' : '' }` }
			onMouseOver={() => handleShowCart( true )}
			onFocus={() => handleShowCart( true )}
			onMouseLeave={() => handleShowCart( false )}
			role="menuitem"
			tabIndex={0}
			ref={ isInitialMount }>
			{ cart && cart.length > 0 ? (
				<>
					<div className="cart-header__header">
						{ cart.length } items in bag
					</div>
					<div className="cart-header__product-list">
						{ cart.map( product => (
							<div className="cart-header__product" key={ product.id }>
								<Link to={`/products/${ product.slug }`} className="cart-header__product-link">
									<GatsbyImage
										className="cart-header__product-image"
										image={ product.images[0].localFile.childImageSharp.gatsbyImageData }
										alt={ product.name } />
								</Link>
								<div className="cart-header__product-meta">
									<Link to={`/products/${ product.slug }`} className="cart-header__product-link">
										<span className="cart-header__product-name">{ product.name }</span>
									</Link>
									<span className="cart-header__product-quantity">Qty: { product.quantity }</span>
									<span className="cart-header__product-price">{ formatPrice( product.price ) }</span>
									<RiDeleteBinLine className="cart-header__product-remove" onClick={ () => removeFromCart( product ) } />
								</div>
							</div>
						) ) }
					</div>
					<div className="cart-header__meta">
						<div className="cart-header__subtotal">Sub Total: { formatPrice( cartSubTotal( cart ) ) }</div>
						<div className="cart-header__button-container">
							<Link to="/cart" className="cart-header__button button" onClick={() => handleShowCart(false)}>
								View Bag
							</Link>
							<Link to="/checkout" className="cart-header__button button" onClick={() => handleShowCart(false)}>
								Checkout
							</Link>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="cart-header__header">
						0 items in bag
					</div>
					<div className="cart-header__body">
						Your bag is empty
					</div>
				</>
			) }
		</div>
	)
}

CartHeader.propTypes = {
	isOpen: PropTypes.bool,
}
