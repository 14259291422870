import React, { createContext, useState } from 'react';

import { getCart, saveCart } from '../helpers/cart'
import { GTRemoveFromCart } from '../helpers/gtagEvents'

export const CartContext = createContext( null );

const CartContextProvider = ( { children } ) => {

	const [ cart, setCart ] = useState( getCart() );
	const [ postage, setPostage ] = useState({ price: 0 });
	const [ shippingState, setShippingState ] = useState({ zoneId: 0 })
	const [ triggerCartEvent, setTriggerCartEvent ] = useState( false )

	const updateCart = ( updatedCart ) => {
		setCart( updatedCart );
		saveCart( updatedCart );
	}

	const addToCart = ( product, quantity = 1, show = true ) => {
		// Get initial state of cart
		const cartCopy = [ ...cart ]
		product.id = parseInt( product.id ) // Forces it to be an integer
	
		const indexOfProduct = cartCopy.findIndex( alreadyInCart => parseInt( alreadyInCart.id ) === parseInt( product.id ) );

		if ( indexOfProduct !== -1 ) {
			// Update the quantity
			cartCopy[ indexOfProduct ].quantity += parseInt( quantity );

			if ( cartCopy[ indexOfProduct ].quantity >= cartCopy[ indexOfProduct ].quantityInStock ) {
				cartCopy[ indexOfProduct ].quantity = cartCopy[ indexOfProduct ].quantityInStock;
			}
	
			if ( cartCopy[ indexOfProduct ].quantity === 0 ) {
				// Remove item from cart when quantity is 0
				cartCopy.splice( indexOfProduct, 1 );
			}
	
		} else {
			product.quantity = quantity;
			// Push the product to the cart
			cartCopy.push( product );
		}
	
		updateCart( cartCopy );
		setTriggerCartEvent( show )

		setTimeout(() => {
			setTriggerCartEvent( false )
		}, 2000)
	}

	const removeFromCart = product => {
		// Get initial state of cart
		const cartCopy = [ ...cart ]

		const indexOfProduct = cartCopy.findIndex( alreadyInCart => parseInt( alreadyInCart.id ) === parseInt( product.id ) )
		const productToRemove = cartCopy[ indexOfProduct ]

		cartCopy.splice( indexOfProduct, 1 )
		updateCart( cartCopy )
		GTRemoveFromCart( productToRemove ) // Trigger gtag event
	}

	const clearCart = () => {
		const emptyCart = [];
		updateCart( emptyCart );
	}

	return (
		<CartContext.Provider value={{ cart, postage, setPostage, addToCart, removeFromCart, clearCart, shippingState, setShippingState, triggerCartEvent }}>
			{ children }
		</CartContext.Provider>
	)
}

export default CartContextProvider