import React, { createContext, useState } from 'react';

import { getCategory, saveCateogry } from '../helpers/category'

export const CategoryContext = createContext( null );

const CategoryContextProvider = ( { children } ) => {

	const fetchedCategory = getCategory()
	const [ category, setCategory ] = useState( fetchedCategory )
	const [ globalSort, setGlobalSort ] = useState( fetchedCategory && fetchedCategory.globalSort ? fetchedCategory.globalSort : { label: 'New In', sort: 'created_at:DESC' } )

	const updateCategory = updatedCategory => {
		setCategory( updatedCategory );
		saveCateogry( updatedCategory );
	}

	const clearCategory = () => {
		const emptyCategory = []
		updateCategory( emptyCategory )
	}

	return (
		<CategoryContext.Provider value={{ category, updateCategory, clearCategory, globalSort, setGlobalSort }}>
			{ children }
		</CategoryContext.Provider>
	)
}

export default CategoryContextProvider;