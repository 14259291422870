import React, { useState } from 'react'
import { Link } from 'gatsby'

import NavItemLevelThree from './NavItemLevelThree'

import { RiArrowDownSLine } from 'react-icons/ri'

export default function NavItemLevelTwo ( props ) {

	const { category, toggleMenu } = props
	const [ active, setActive ] = useState( false )

	const toggleSubNav = () => {
		setActive( !active )
	}

	return (
		<li className={`navigation__item navigation__item--has-dropdown ${active ? 'is-open': ''}`}>
			<div className="navigation__dropdown">
				<div className="navigation__link-container">
					<Link
						to={ category.navigationSlug }
						className="navigation__link"
						activeClassName="active"
						onClick={() => toggleMenu( false )}>
						{ category.name }
					</Link>
					<RiArrowDownSLine className={`navigation__dropdown-icon ${active ? 'is-open': ''}`} onClick={ () => toggleSubNav(  ) } />
				</div>
				<ul className={`navigation__dropdown-options ${active ? 'is-open': ''}`}>
					{ category.subCategories.map( subCat => (
						<React.Fragment key={ subCat.id }>
							{ subCat.subCategories.length ? (
								<NavItemLevelThree category={ subCat } toggleMenu={ toggleMenu } />
							) : (
								<li className="navigation__dropdown-option">
									<Link
										to={ subCat.navigationSlug }
										className="navigation__link navigation__link--in-dropdown"
										activeClassName="active"
										onClick={() => toggleMenu( false )}>
										{ subCat.name }
									</Link>
								</li>
							) }
						</React.Fragment>
					) ) }
				</ul>
			</div>
		</li>
	)

}