/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./Footer/Footer"

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					menuLinks {
						name
						link
					}
				}
			}
		}
	`)

	return (
		<>
			<Header menuLinks={ data.site.siteMetadata.menuLinks } />
			{ /* Move the static query to the header component so its handle seperately and not here */}
			{/* Can then just render <Header /> - all handled at component level */}
			<main className="main-content">
				<div className="body-content">
					{/* Temp wrapper, should be applied to each component */}
					{/* <div className="wrapper">  */}
						{children}
					{/* </div> */}
					<Footer />
				</div>
			</main>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout