import React, { useState, useEffect, useRef, useCallback } from "react";
import { navigate, Link } from 'gatsby'
import { useQuery, gql } from '@apollo/client';
import { FaSpinner } from 'react-icons/fa'
import { RiSearchLine, RiCloseLine } from 'react-icons/ri'

const Search = (props) => {
	const searchComponent = useRef();
	const inputRef = useRef();
	const { toggleSearch } = props;
	const limit = 20;

	const [state, setState] = useState({ query: '' });
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [filterState, setFilterState] = useState({
		_or: [
			{ name_contains: "" },
			{ tags: { name_contains: "" } },
			{ manufacturer: { name_contains: "" } }
		]
	});

	const productQuery = gql`
		query productQuery($start: Int, $limit: Int, $where: JSON) {
			products(start: $start, limit: $limit, where: $where) {
				name
				slug
				id
			}
		}
	`;

	const { error, data } = useQuery(productQuery, {
		variables: {
			start: 0,
			limit: limit,
			where: filterState
		},
		fetchPolicy: "cache-first"
	});

	const handleInputChange = event => {
		const query = event.target.value;

		setState({ query });
		setLoading(true);

		if (query.length > 2 && query.length < 50) {
			setFilterState({
				_or: [
					{ name_contains: query },
					{ tags: { name_contains: query } },
					{ manufacturer: { name_contains: query } }
				]
			});
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		navigate(`/products?search=${state.query}`);
		toggleSearch(false);
		setState({ query: '' });
	};

	const escFunction = useCallback(event => {
		if (searchComponent.current.contains(event.target)) {
			if (event.keyCode === 27) { // escape key
				toggleSearch(false);
			}
			return;
		} else {
			toggleSearch(false);
		}
	}, [toggleSearch]);

	useEffect(() => {
		const listener = (event) => escFunction(event);
		setTimeout(() => inputRef.current.focus(), 250);

		document.addEventListener("keydown", listener, false);
		document.addEventListener("mousedown", listener, false);

		return () => {
			document.removeEventListener("keydown", listener, false);
			document.removeEventListener("mousedown", listener, false);
		};
	}, [toggleSearch, escFunction]);

	useEffect(() => {
		if (data) {
			setProducts(data.products);
			setLoading(false);
		}
	}, [data, state.query]);

	if (error) return <p>Error :(</p>;

	return (
		<>
			<div className="search" ref={searchComponent}>
				<form
					className="search__form"
					onSubmit={(e) => handleSubmit(e)}
				>
					<input
						className="search__bar"
						type="text"
						aria-label="Search"
						placeholder="Search for items or manufacturers"
						onChange={handleInputChange}
						value={state.query}
						ref={inputRef}
					/>
					<button
						type="submit"
						className="search__submit"
						aria-label="Submit search"
					>
						<RiSearchLine className="search__submit-icon" />
					</button>
				</form>

				<div className="search__products-container">
					{state.query && state.query.length > 2 && !!products.length &&
						<ul className="search__products-list">
							{loading ? (
								<div className="search__loading-container">
									<h3 className="search__loading-text">Fetching products</h3>
									<FaSpinner className="loader" />
								</div>
							) : (
								<>
									{products.map(product => (
										<React.Fragment key={product.id}>
											<li className="search__products-item">
												<Link to={`/products/${product.slug}`} className="search__products-link" onClick={() => toggleSearch(false)}>
													{product.name}
												</Link>
											</li>
										</React.Fragment>
									))}
								</>
							)}
						</ul>
					}
				</div>

				<RiCloseLine
					className="search__close"
					role="button"
					onClick={() => toggleSearch(false)} />
			</div>
		</>
	);
}

export default Search;
