import React from 'react'
import { ApolloProvider } from '@apollo/client';

import { client } from './client';

import AuthContextProvider from './src/context/auth/AuthState'
import CartContextProvider from './src/context/CartContext'
import CategoryContextProvider from './src/context/CategoryContext'

import Layout from './src/components/layout'
import CookiePolicy from './src/components/Cookie/CookiePolicy'

import './src/styles/toolkit.scss'

export const wrapRootElement = ({ element }) => (
	<ApolloProvider client={ client }>
		<AuthContextProvider>
			<CartContextProvider>
				<CategoryContextProvider>
					<Layout>
						{ element }
						<CookiePolicy />
					</Layout>
				</CategoryContextProvider>
			</CartContextProvider>
		</AuthContextProvider>
	</ApolloProvider>
)