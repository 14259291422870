import React, { useReducer, useEffect, useState, useCallback, useMemo } from 'react'
import axios from 'axios'

import AuthContext from './AuthContext';
import AuthReducer from './authReducer';

export default function AuthProvider({ children }) {

	const DEFAULT_STATE = useMemo(() => ({
		user: {},
		loggedIn: false
	}), []);

	const [ state, dispatcher ] = useReducer( AuthReducer, DEFAULT_STATE )
	const [ loadingUser, setLoadingUser ] = useState( true )

	// Checks if user exists in local storage, if not render intial state.
	const checkStorageForUser = useCallback(async () => {

		if ( typeof window === 'undefined' ) {
			return DEFAULT_STATE;
		}

		const userInStorage = JSON.parse(localStorage.getItem('user')) || false;

		if ( userInStorage ) {
			try {
				const userIdParam = userInStorage.user.id
				const { data: payload } = await axios.get( `/api/users/${ userIdParam }` );
				dispatcher( { type: 'LOGIN', payload } )
			} catch( e ) {
				dispatcher( { type: 'LOGOUT' } )
			}
		} else {
			dispatcher( { type: 'LOGOUT' } )
		}

		setLoadingUser( false )
	}, [dispatcher, DEFAULT_STATE]);

	useEffect(() => {
		checkStorageForUser()
	}, [checkStorageForUser])

	return (
		<AuthContext.Provider value={{ state, dispatcher, loadingUser }}>
			{children}
		</AuthContext.Provider>
	)
}
